$colors: ( 
	"label1": #77A361, 
	"label2": #CD6889, 
	"label3": #2F4F4F, 
	"label4": #104E8B, 
	"label5": #A020F0, 
	"label6": #FF8000, 
	"taskboardicons": #6c6689, 
	"success": #1a9e3e,
	"whitelight": #FFF,
	"lightGrey": #F4F5F7,
	"nevy": #563c91,
	"warning": #d79609,
	"error": #e41010
);

$widgets: (
	"greytext": #6c757d,
	"lighttext": #575d78
);

@each $key, $value in $colors {
	.#{$key} {
	  background: $value;
	  background-color: $value !important;
	  border-color: $value !important;
	}
	.#{$key}--text {
	  color: $value !important;
	}
	.#{$key}-c-hover {
	  &:hover {
		color: $value !important;
	  }
	}
	.#{$key}-b-hover {
	  &:hover {
		background-color: $value !important;
	  }
	}
}

@each $w, $value in $widgets {
	.#{$w} {
	  background: $value;
	  background-color: $value !important;
	  border-color: $value !important;
	}
	.#{$w}--text {
	  color: $value !important;
	}
	.#{$w}-c-hover {
	  &:hover {
		color: $value !important;
	  }
	}
	.#{$w}-b-hover {
	  &:hover {
		background-color: $value !important;
	  }
	}
}