.aspect-ratio-image {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Adjust this to match your image's aspect ratio */
    object-fit: cover;
  }

  .termsContainer {
    background-color: #222437;
    height: 100vh;
    overflow: scroll;
  }

  .widget-dark-color {
    color: #fff;         
  }

  .paragraph {
    color: #fff;
  }